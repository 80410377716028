import React, { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import withClientOnly from './withClientOnly';

interface ProviderProps {
  children: ReactNode;
  siteKey: string;
  languageCode: string;
  appState: any;
}

const ClientOnlyReCaptchaProvider: React.FC<ProviderProps> = ({
  children,
  siteKey,
  languageCode,
  appState,
}) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={siteKey}
    language={languageCode}
    container={{
      parameters: {
        badge: 'inline',
        theme: appState.darkMode ? 'dark' : 'light',
      },
    }}
  >
    {children}
  </GoogleReCaptchaProvider>
);

export default withClientOnly(ClientOnlyReCaptchaProvider);
