import React, { useEffect, useState } from 'react';

const withClientOnly = (WrappedComponent: any) => {
  return (props: any) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
      setIsClient(true);
      console.log('dotsite component mounted')
    }, []);

    return isClient ? <WrappedComponent {...props} /> : null;
  };
};

export default withClientOnly;