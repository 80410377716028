import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import loadable from 'react-loadable-visibility/loadable-components';
import { routePatterns } from './lib/routerUtils';
import { LayoutFactory } from './components/Layouts/LayoutFactory';
import { useAppStateContext } from './lib/AppStateProvider';
import { NavBarContainer } from './components/Header/NavBarContainer';
import { ScrollToTop, tealiumClickCustomFunc } from '@dtcm/ui-components';
import { get, canUseDOM } from './lib/getPropertyByPath';
import { getGlobalDictionaryTerm } from '@dtcm/ui-components/dist/utils';
import { SkipContentButtons } from '@dtcm/ui-components';

function CloseIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path
                fill="#000"
                fillRule="nonzero"
                d="M13.72 3.22l1.06 1.06L10.06 9l4.72 4.72-1.06 1.06L9 10.06l-4.72 4.72-1.06-1.06L7.94 9 3.22 4.28l1.06-1.06L9 7.94l4.72-4.72z"
            />
        </svg>
    );
}

const FooterContainer: any = loadable(() =>
  import(/* webpackChunkName: "FooterContainer" */ './components/Footer/FooterContainer')
);

/*
  APP LAYOUT
  This is where the app's HTML "shell" should be defined.

  All routes share this root layout by default,
  but components added to inner placeholders are route-specific.
*/

function Layout() {
  const { appState } = useAppStateContext();
  const sitecoreContext = appState.sitecoreContextData;
  const alertBanner = get(sitecoreContext, 'configuration.alertBanner');
  const [showAlertBanner, setShowAlertBanner] = React.useState<boolean>(true);
  const isEmptyLayout = get(appState, 'dictionary.viewBag.isEmptyLayout', false);
  const breadcrumb = get(appState, 'sitecoreContextData.breadcrumb', []);
  const canonical = get(appState, 'sitecoreContextData.canonical', '');
  const btnLabel: string = getGlobalDictionaryTerm(sitecoreContext, 'closeAnnouncementBannerLabel') || "Close announcement banner";
  const timing = canUseDOM ? get(window, 'performance.timing', {}) : {};
  const performanceSpeedDetails = () => {
    if (typeof performance !== 'undefined') {
      const { navigationStart, domContentLoadedEventEnd, loadEventEnd } = timing;
      const pageLoadTime = (loadEventEnd - navigationStart) / 1000;
      const domLoadTime = (domContentLoadedEventEnd - navigationStart) / 1000;

      if (pageLoadTime > 0) {
        return {pageLoadTime, domLoadTime};
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  React.useEffect(() => {
    let eventAct = '';

    if (breadcrumb.length > 1) {
      const [, val] = breadcrumb;
      eventAct = get(val, 'label', '');
    } else if (breadcrumb.length === 1) {
      const [val] = breadcrumb;
      eventAct = get(val, 'label', '');
    }
    setTimeout(() => {
      const {pageLoadTime, domLoadTime} = performanceSpeedDetails() || {};
      if (pageLoadTime && domLoadTime) {
        const { pathname } = new URL(canonical);
        const eventLab = pathname.split('/').slice(2).join('/');
        tealiumClickCustomFunc({
          event_name: 'eventTracker',
          event_cat: 'Modules',
          event_act: eventAct,
          event_lab: eventLab,
          page_load_time: pageLoadTime,
          dom_load_time: domLoadTime,
        },                     {} as any);
      }
    }, 500);

  }, [
    timing!.domContentLoadedEventEnd,
    timing!.navigationStart,
    timing!.loadEventEnd,
    canUseDOM
  ]);
  
  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`${process.env.PUBLIC_URL}/assets/styles/${
              appState.sitecoreContextData.rtl ? 'ar' : 'en'
            }.min.css?v=6176-5`}
          />
        </Helmet>
        <SkipContentButtons />
        <header className="dot-site-header">
          <div id="announcement-banner" />
          {alertBanner && alertBanner.text && showAlertBanner ? (
            <div className={`alertBanner d-print-none theme-contrast ${alertBanner.theme}`}>
              <div className="container">
                <div className="module-group">
                  <div className="alertBanner__row">
                    <div
                      className="alertBanner__text"
                      dangerouslySetInnerHTML={{
                        __html: get(appState, 'sitecoreContextData.configuration.alertBanner.text'),
                      }}
                    />
                    <div className="alertBanner__button">
                      <button onClick={() => setShowAlertBanner(false)} aria-label={btnLabel}>
                        <span className="accessibility-hidden-text">{btnLabel}</span>
                        <CloseIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <NavBarContainer />
        </header>
        <main className="main overflowXClip">
          <Switch>
            {routePatterns.map((routePattern) => (
              <Route key={routePattern} path={routePattern} component={LayoutFactory} />
            ))}
          </Switch>
          {!isEmptyLayout && <ScrollToTop offset={100} analytics={get(appState, 'analytics')} />}
          <div id="event-remind-confirmations-wrapper"></div>
        </main>
        {/*
      We need to provide content for the loading placeholder used for the footer. This ensures
      that the dynamic loading script triggers for the Footer component. Without any content, it
      seems that the IntersectionObserver never intersects with the Footer component and therefore
      the dynamic loading script doesn't load the component.

      NOTE: most components will not need content in order for dynamic loading to work. The Footer
      seems to be more of an edge case.
      */}
        <FooterContainer />
    </React.Fragment>
  );
}

export default Layout;
