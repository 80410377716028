import { LanguageDefinition } from '@dtcm/ui-components';

// If we have a state, and that state has language data, return the language
export const resolveLanguageFromContextData = (
  sitecoreContextData?: { language?: string; languageName?: string } | undefined
): LanguageDefinition => {
  if (!sitecoreContextData || !sitecoreContextData.language || !sitecoreContextData.languageName) {
    console.warn('context data is undefined or language is not defined in context data', sitecoreContextData);
    return {
      languageCode: '',
      languageName: '',
    };
  }
  return {
    languageCode: sitecoreContextData.language,
    languageName: sitecoreContextData.languageName,
  };
};

export const resolveLanguageFromConfig = (config: {
  defaultLanguage: string;
  defaultLanguageName: string;
}): LanguageDefinition => {
  const values = {
    languageCode: '',
    languageName: '',
  };
  if (!config) {
    console.error('config is undefined');
    return values;
  }
  values.languageCode = config.defaultLanguage;
  values.languageName = config.defaultLanguageName;
  return values;
};
