import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppRoot from './AppRoot';
import config from './temp/config';
import { resolveLanguageFromContextData, resolveLanguageFromConfig } from './lib/languageResolvers';
import { matchRoute } from './lib/routerUtils';
import { AppStateProvider, AppState } from './lib/AppStateProvider';
import { resolveAccessibilitySettings } from './lib/accessibilityStateManager';
import { LanguageDefinition } from '@dtcm/ui-components';
// import { loadableReady } from '@loadable/component';
// import json from './tempSsrJson.json';

/* eslint-disable no-underscore-dangle */

/*
  App Rendering
*/

renderApp();

function renderApp() {
  const initialState = resolveInitialAppState();

  // HTML element to place the app into
  const rootElement = document.getElementById('root');

  // Create the Router component that will be used for client-side routing.
  const Router: React.FC = (routerProps) => <BrowserRouter>{routerProps.children}</BrowserRouter>;

  // when React initializes from a SSR-based initial state, you need to render with `hydrate` instead of `render`
  const renderFunction = initialState.routeData ? ReactDOM.hydrate : ReactDOM.render;

  // loadableReady(() => {
  renderFunction(
    <AppStateProvider initialState={initialState}>
      <AppRoot Router={Router} />
    </AppStateProvider>,
    rootElement
  );
  // });
}

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/

function resolveInitialAppState() {
  const ssrRawJson = document.getElementById('__JSS_STATE__');
  const ssrState = ssrRawJson ? JSON.parse(ssrRawJson.innerHTML) : null;
  //  const ssrState = json; 
  const routePath = resolveRoutePath();

  /*
  Language Resolving
  Attempt to resolve the app language prior to rendering the app.
  */
  const language = resolveLanguage(ssrState);

  const accessibilitySettings = resolveAccessibilitySettings(config);

  const appState: AppState = {
    routePath,
    language,
    routeData: null,
    sitecoreContextData: {},
    selectedContrastValue: accessibilitySettings.contrast,
    selectedFontSize: accessibilitySettings.fontSize,

  };

  if (ssrState) {
    const {
      dictionary,
      navbar,
      footer,
      downloadBox,
      newsletterForm,
      footerWeatherWidget,
      routeData,
      sitecoreContextData,
      analytics,
      accessWidget
    } = ssrState;

    return {
      ...appState,
      dictionary: dictionary || null,
      routeData: routeData || null,
      navbar,
      footer,
      downloadBox,
      newsletterForm,
      footerWeatherWidget,
      sitecoreContextData,
      analytics,
      accessWidget
    } as AppState;
  }

  return appState;
}

// Resolve the current matched router path
function resolveRoutePath() {
  const matchedRoute = matchRoute(window.location.pathname);
  const routeParams: { sitecoreRoute?: string } = (matchedRoute && matchedRoute.params) || {};
  return (routeParams && routeParams.sitecoreRoute) || '/';
}

function resolveLanguage(ssrState: any): LanguageDefinition {
  // If SSR data exists, we try to extract the language from that data.
  let language: LanguageDefinition =
    ssrState && ssrState.sitecoreContextData && resolveLanguageFromContextData(ssrState.sitecoreContextData);

  // If a language parameter is found in the route URL and the parameter has a different value
  // than specified in SSR data, use the parameter value. This shouldn't ever happen, but
  // anything is possible.
  const matchedRoute = matchRoute(window.location.pathname);
  if (
    matchedRoute &&
    matchedRoute.params &&
    matchedRoute.params.lang &&
    language &&
    language.languageCode !== matchedRoute.params.lang
  ) {
    // note: this will leave us in an incomplete state, i.e. languageName cannot be resolved from URL
    // again, this scenario shouldn't ever happen.
    language.languageCode = matchedRoute.params.lang;
  }

  // If no language could be found in SSR data or route URL, then fallback to the value defined in config.
  if (!language) {
    language = resolveLanguageFromConfig(config);
  }

  return language;
}
